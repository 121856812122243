import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createNewOrder,
  createOrderFailure,
  getOrderAddressesInfo,
} from "@/actions/orders.actions";

import { RouteComponentProps } from "@reach/router";
import { Link, navigate } from "gatsby";
import { navigate as reach_navigate } from "@reach/router";
import { Controller, FormProvider, useForm } from "react-hook-form";
import cn from "classnames";

import { CheckoutType, PaymentMethodsType, SupplierType, UserCartItemType } from "app/types";

import useMobile from "@/hooks/useMobile";

import { AppStateType } from "@/reducers";

import RadioButton from "@/components/ui-kit/radioButton";
import Input from "@/components/ui-kit/input";
import Datepicker from "@/components/ui-kit/datepicker";
import Select from "@/components/ui-kit/select";
import Button from "@/components/ui-kit/button";
import Counter from "@/components/ui-kit/counter";
import Textarea from "@/components/ui-kit/textarea";

import css from "./checkout.modules.scss";

import { paymentMethodsNames } from "@/utils/paymentMethodsNames";
import { Tooltip } from "antd";
import { groupUserCartItemsBySupplier } from "@/utils/groupUserCartItemsBySupplier";

interface ICheckout extends RouteComponentProps {
  supplierId?: number;
}

const commercialOfferTypes = [
  // TODO: временно скрываем Автогенерируемое
  // { label: "Автогенерируемое", value: "autogenerated_commercial_offer" },
  { label: "На бланке", value: "formalized_commercial_offer" }
];

const paymentTypes = [
  { label: "Предоплата", value: "prepay" },
  { label: "Постоплата", value: "postpay" },
  { label: "Частичная предоплата", value: "partial_prepay" },
];

const Checkout: FC<ICheckout> = (props) => {
  const [isCreatingOrderSuccess, setCreatingOrderSuccess] = useState<boolean>(false);
  const [addressFromErr, setAddressFromErr] = useState<string>(null);
  const [paymentTypeFormErr, setPaymentTypeFormErr] = useState<string>(null);
  const [payment, setPayment] = useState<PaymentMethodsType>(null);
  const [supplierOrder, setSupplierOrder] = useState<SupplierType[]>([]);
  const [productQuantity, setProductQuantity] = useState<number>(0);
  const [orderTotalPrice, setOrderTotalPrice] = useState<number>(0);

  const dispatch = useDispatch();

  const { supplierId } = props;

  const { userCart } = useSelector((state: AppStateType) => state.cart);
  const { orderIds, error, orderAddresses } = useSelector((state: AppStateType) => state.orders);

  const checkoutForm = useForm<CheckoutType>({
    defaultValues: {
      receiveType: "delivery",
      deliveryAddress: null,
      date: String(new Date()),
      time: {
        value: "17:00",
        label: "до 17:00",
      },
      paymentPercent: 30,
      paymentType: null,
      commercialOfferType: {
        value: "formalized_commercial_offer",
        label: "На бланке",
      }
    },
  });

  const groupedCartItemsBySupplier: UserCartItemType[][] = groupUserCartItemsBySupplier(userCart?.items);

  const { isMobile } = useMobile();
  const { errors, control, setValue,  watch, getValues } = checkoutForm;
  const { receiveType, paymentType, deliveryAddress } = watch();

  useEffect(() => {
    if (orderIds) setCreatingOrderSuccess(true);
  }, [orderIds]);

  useEffect(() => {
    if (supplierId) {
      const currenProductQuantity: number = groupedCartItemsBySupplier
        ?.find((supplierItems: UserCartItemType[]) => supplierItems?.[0]?.supplier?.id === +supplierId)?.length;
      const currentSupplierOrder = groupedCartItemsBySupplier?.map((supplierItems: UserCartItemType[]) => supplierItems?.[0]?.supplier)?.filter((supplier: SupplierType) => supplier?.id === +supplierId);
      const currentOrderTotalPrice: number = groupedCartItemsBySupplier
        ?.find((supplierItems: UserCartItemType[]) => supplierItems?.[0]?.supplier?.id === +supplierId)
        ?.map((item: UserCartItemType) => +item.value)
        ?.reduce((acc, cur) => acc + cur, 0);

      setSupplierOrder(currentSupplierOrder);
      setProductQuantity(currenProductQuantity);
      setOrderTotalPrice(currentOrderTotalPrice);
    }
  }, [supplierId]);

  useEffect(() => {
    !props.location.state && navigate("/platform/cart");

    return () => clearOrderInfo();
  }, [props.location.state]);

  useEffect(() => {
   dispatch(getOrderAddressesInfo(1, 25));
  }, []);

  const timeOptions = useMemo(() => {
    const realHours = new Date().getHours() + 1;

    const timers = [];

    for (let i = realHours; i < 24; i++) {
      const timer = `${i < 10 ? `0${i}` : i}:00`;
      timers.push({
        value: timer,
        label: `до ${timer}`,
      });
    }

    return timers;
  }, []);

  const subtitleStyles: string = cn(`mb-5 tablet:mb-8`, {
    "text-x3": !isMobile,
    "text-x2": isMobile,
  });

  const hasErrors = () => {
    if (!getValues().paymentType) {
      setPaymentTypeFormErr("Выберите способ оплаты");
    }
    if (!getValues().deliveryAddress && getValues("receiveType") !== "pickup") {
      setAddressFromErr("Укажите адрес доставки");
    }
  };

  useEffect(() => {
    if (!!getValues().paymentType) {
      setPaymentTypeFormErr("");
    }
    if (!!getValues().deliveryAddress) {
      setAddressFromErr("");
    }
    if (getValues("receiveType") === "pickup") {
      setAddressFromErr("");
    }
  }, [getValues().paymentType, getValues().deliveryAddress]);

  const submitCheckoutForm = (e) => {
    e.preventDefault();
    hasErrors();

    const deliveryDate = new Date(getValues("date")).toISOString().split('T')[0];

    if (paymentTypeFormErr === "" && addressFromErr === "") {
      dispatch(createNewOrder(
        getValues("receiveType"),
        getValues("deliveryAddress") || "",
        getValues("paymentType")?.value?.toString(),
        deliveryDate,
        getValues("comment"),
        getValues("commercialOfferType")?.value as string,
        userCart?.items?.filter(({supplier}) => supplier?.id === +supplierId)
      ))
    }
  };

  const prevOrderAddresses: string[] = orderAddresses?.slice(-6)?.filter((address: string): boolean => address !== "");

  const clearOrderInfo = (): void => {
    setCreatingOrderSuccess(false);
    dispatch(createOrderFailure());
  };

  return (
    <div
      data-aos="fade-in"
      data-aos-once="true"
      className="mt-20 tablet:mt-15"
    >
      <h1
        className={cn(`mb-8 tablet:mb-15 text-x1`)}
      >
        Оформление заказа
      </h1>
      {isCreatingOrderSuccess && (
        <div data-aos="fade-in" data-aos-once="true">
          {orderIds?.map((orderId: number) => (
            <p key={orderId} className={`text-t1 mb-10 tablet:mb-8 ${css.completedText}`}>
              Заказ <Link to={`/platform/order/${orderId}`}><span className="text-electric-blue" onClick={clearOrderInfo}>№ {orderId} </span></Link>
              успешно оформлен, за его продвижением можно следить в разделе Заказы
            </p>
          ))}
          <div className="flex flex-col tablet:flex-row">
            {orderIds?.length === 1 && (
              <Button
                onClick={() => {
                  reach_navigate(`/platform/order/${orderIds[0]}`);
                  clearOrderInfo();
                }}
                theme="filled"
                className="mb-3 tablet:mb-0 tablet:mr-4"
              >
                Перейти в заказ
              </Button>
            )}
            <Button
              onClick={() => {
                reach_navigate("/platform/cart");
                clearOrderInfo();
              }}
            >
              Вернуться в корзину
            </Button>
          </div>
        </div>
      )}
      {!isCreatingOrderSuccess && (
        <div className="flex flex-col-reverse tablet:flex-row">
          <div className={`mr-0 tablet:mr-4 ${css.contentWrapper}`}>
            <FormProvider {...checkoutForm}>
              <form
                onSubmit={submitCheckoutForm}
                className={`flex flex-col ${css.wrapper}`}
              >
                <div
                  className={cn({
                    "mb-6 tablet:mb-8": receiveType === "pickup",
                    "mb-8 tablet:mb-7": receiveType === "delivery",
                  })}
                >
                  <h2 className={subtitleStyles}>
                    Способ получения
                  </h2>
                  <div className="flex flex-col tablet:flex-row">
                    <Controller
                      control={control}
                      name="receiveType"
                      render={({ onChange }) => (
                        <RadioButton
                          id="delivery"
                          value="delivery"
                          checked={receiveType === "delivery"}
                          onChange={onChange}
                          label="Доставка"
                          sublabel="Рассчитывается поставщиком"
                          className="flex-1 mb-2 tablet:mb-0 tablet:mr-4"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="receiveType"
                      render={({ onChange }) => (
                        <RadioButton
                          id="pickup"
                          value="pickup"
                          checked={receiveType === "pickup"}
                          onChange={onChange}
                          label="Самовывоз"
                          sublabel="Дополнительно обсуждайте с поставщиком"
                          className={`flex-1  ${css.radio}`}
                        />
                      )}
                    />
                  </div>
                  {receiveType === "delivery" && (
                    <div>
                      <Controller
                        control={control}
                        name="deliveryAddress"
                        rules={{ required: true }}
                        render={({ onChange, value }) => (
                          <>
                            {isMobile && (
                              <Textarea
                                placeholder="Адрес доставки *"
                                theme="white"
                                className={`mt-2 tablet:mt-8 mb-5 tablet:mb-4 ${css.addressTextarea}`}
                                value={value}
                                onChange={onChange}
                                isError={!!errors.deliveryAddress}
                              />
                            )}
                            {!isMobile && (
                              <Input
                                placeholder="Адрес доставки *"
                                theme="white"
                                className="mt-2 tablet:mt-8 mb-5 tablet:mb-4"
                                value={value}
                                onChange={onChange}
                                isError={!!errors.deliveryAddress}
                              />
                            )}
                          </>
                        )}
                      />
                      {!!addressFromErr && (
                        <div className="text-n1 text-error">
                          {addressFromErr}
                        </div>
                      )}
                      <h4 className="text-n2 text-blue-grey mb-3 tablet:mb-4">
                        Ранее заказывали
                      </h4>
                      <ul className="flex flex-wrap flex-col tablet:flex-row">
                        {prevOrderAddresses?.map((address: string, index: number) => (
                          <li
                            className={`mb-3 tablet:mb-4 ${css.addressesItem}`}
                            key={index}
                          >
                            <RadioButton
                              id={`delivery-${index}`}
                              value={address}
                              checked={address === deliveryAddress}
                              onChange={() =>
                                setValue("deliveryAddress", address, {
                                  shouldValidate: true,
                                })
                              }
                              sublabel={address}
                              className={`w-full ${css.radioAddress}`}
                              notChosen={
                                !orderAddresses.includes(deliveryAddress)
                              }
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <h3 className="text-s1 mb-3 tablet:mb-4">
                  {`Дата ${receiveType === "delivery" ? "доставки" : "самовывоза"}`}
                </h3>
                <div className="flex mb-10 tablet:mb-15">
                  <Controller
                    control={control}
                    name="date"
                    render={({ onChange, value }) => (
                      <Datepicker
                        isPrevDatesDisabled
                        containerClassName={`mr-1`}
                        dateFormat="D MMMM, dddd"
                        value={value}
                        showCalendarActions
                        onChange={onChange}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="time"
                    render={({ onChange, value }) => (
                      <Select
                        placeholder=""
                        options={timeOptions}
                        onChange={onChange}
                        className={css.time}
                        value={value}
                        isSearchable={false}
                        isClose={false}
                      />
                    )}
                  />
                </div>
                <div className="tablet:flex justify-between">
                  <div className="mb-10 flex flex-col justify-between tablet:mb-8">
                    <h2 className={subtitleStyles}>
                      Способ оплаты
                    </h2>
                    <div className="flex flex-col tablet:items-center tablet:flex-row">
                      <Controller
                        control={control}
                        name="paymentType"
                        rules={{required: true}}
                        defaultValue={payment}
                        render={({onChange, value}) => (
                          <Select
                            value={value}
                            placeholder="Тип оплаты *"
                            options={paymentTypes}
                            onChange={onChange}
                            isError={!!errors.paymentType}
                            className={`mr-0 tablet:mr-4 ${css.payment}`}
                            isSearchable={false}
                            isClose={false}
                          />
                        )}
                      />
                      {/*TODO: выяснить, что за 3 и нужен ли вообще этот блок*/}
                      {/*{paymentType && paymentType.value === 3 && (*/}
                      {/*  <div className="flex flex-col items-center mt-5 tablet:mt-0">*/}
                      {/*    <p className="text-n2 mr-6 text-blue-grey">*/}
                      {/*      Выберите размер*/}
                      {/*    </p>*/}
                      {/*    <Controller*/}
                      {/*      rules={{required: true}}*/}
                      {/*      control={control}*/}
                      {/*      name="paymentPercent"*/}
                      {/*      render={({onChange, value}) => (*/}
                      {/*        <Counter*/}
                      {/*          min={30}*/}
                      {/*          max={90}*/}
                      {/*          step={5}*/}
                      {/*          value={value}*/}
                      {/*          setValue={onChange}*/}
                      {/*        />*/}
                      {/*      )}*/}
                      {/*    />*/}
                      {/*  </div>*/}
                      {/*)}*/}
                    </div>
                    {!!paymentTypeFormErr && (
                      <div className="mt-5 text-n1 text-error">
                        {paymentTypeFormErr}
                      </div>
                    )}
                  </div>
                  <div className="mb-10 flex flex-col justify-between tablet:mb-8">
                    <h2 className={subtitleStyles}>Форма коммерческого предложения</h2>
                    <Controller
                      control={control}
                      name="commercialOfferType"
                      rules={{required: true}}
                      render={({onChange, value}) => (
                        <Select
                          placeholder="Тип формы *"
                          value={value}
                          options={commercialOfferTypes}
                          onChange={onChange}
                          className={`mr-0 ${css.payment}`}
                          isSearchable={false}
                          isError={!!errors.commercialOfferType}
                          isClose={false}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="mb-10 tablet:mb-8 mt-10 tablet:mt-8">
                  <h2 className={subtitleStyles}>
                    Комментарий к заказу
                  </h2>
                  <Controller
                    control={control}
                    name="comment"
                    render={({onChange, value}) => (
                      <Textarea
                        className={`${css.comment}`}
                        placeholder={value ? "" : "Напишите информацию, которая может быть полезна поставщику. К примеру, какие документы приложить, как к вам удобнее проехать, в каком месте разгрузиться, на какой этаж донести и т.д."}
                        theme="white"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </div>
                <Button
                  theme="filled"
                  className={`${css.button}`}
                  type="submit"
                >
                  Оформить
                </Button>
              </form>
              {!!error && (
                <div className="mt-5 text-error text-n1">
                  {Array.isArray(error)
                    ? error.map((err, index) => {
                      return <span key={index} className="block mb-2">{err.msg}</span>
                    })
                    : error
                  }
                </div>
              )}
            </FormProvider>
          </div>
          <aside
            className={`bg-white mb-8 tablet:mb-0 tablet:mt-13 ${css.aside}`}
          >
            <ul className={css.asideList}>
              <li className="flex items-center mb-3 tablet:mb-4">
                <p className={`text-n2 text-blue-grey ${css.asideTitle}`}>
                  Поставщик
                </p>
                {supplierOrder[0]?.name?.length > 80 ? (
                  <Tooltip title={supplierOrder[0]?.name}>
                    <span className="text-s1">
                      {supplierOrder[0]?.name?.substring(0, 80).trim()}
                    </span>
                    {supplierOrder[0]?.name?.length > 80 && (
                      <span>...</span>
                    )}
                  </Tooltip>
                ) : (
                  <p className={`text-s1 font-medium`}>{supplierOrder[0]?.name}</p>
                )}
              </li>
              <li className="flex items-center mb-3 tablet:mb-4">
                <p className={`text-n2 text-blue-grey ${css.asideTitle}`}>
                  {`Товаров (${productQuantity})`}
                </p>
              </li>
              <li className="flex items-center mb-3 tablet:mb-4">
                <p className={`text-n2 text-blue-grey ${css.asideTitle}`}>
                  Доставка
                </p>
                <p className={`text-s1 ${css.asideText}`}>--</p>
              </li>
            </ul>
          </aside>
        </div>
      )}
    </div>
  );
};

export default Checkout;
