import React, {
  FC,
  useState,
  useEffect,
} from "react";
import {
  navigate,
  RouteComponentProps,
} from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import {
  getRolesInfo,
  getRolePermsInfo,
  getUsersListInfo,
  fetchUserInfo,
} from "@/actions/account.actions";
import { AppStateType } from "@/reducers";
import { roleResolver } from "@/utils/roleResolver";
import { RoleList } from "./RolesList/RoleLIst";
import { UsersList } from "./UsersList/UsersList";
import { LimitSettingsPage } from "./LimitSettingsPage/LimitSettingsPage";
import { GeneralSettings } from "./GeneralSettings/GeneralSettings";
import { NewslettersNotifications } from "./NewslettersNotifications/NewslettersNotifications";
import { AboutCompany } from "./AboutCompany/AboutCompany";
import UserProfile from "@/components/pages/platform/pages/settings/UserProfile/UserProfile";
import DistributionOfOrders from "@/components/pages/platform/pages/settings/DistributionOfOrders/DistributionOfOrders";
import { Tabs, TabsProps } from "antd";

import "@/assets/scss/global.scss";
import "@/assets/global.css";

interface SettingsPropsType extends RouteComponentProps {}

const Settings: FC<SettingsPropsType> = () => {
  const [isActiveRoleListPage, setActiveRoleListPage] = useState<boolean>(true);
  const [isActiveUsersListPage, setActiveUsersListPage] = useState<boolean>(true);
  const [isActiveCreateUserForm, setActiveCreateUserForm] = useState<boolean>(false);
  const [isActiveCreateRoleForm, setActiveCreateRoleForm] = useState<boolean>(false);
  const [isActiveCreateDepartmentForm, setActiveCreateDepartmentForm] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { userInfo } = useSelector((state: AppStateType) => state.account);
  const { isSupplier } = roleResolver(userInfo?.roles);

  useEffect(() => {
    // TODO: пока скрываем
    // dispatch(getRolesInfo());
    // dispatch(fetchUserInfo());
    // dispatch(getRolePermsInfo());
    // dispatch(getUsersListInfo(1, 25));
  }, []);

  const callback = (key: string) => {
    // TODO: пока скрываем
    // if (key === "1") {
    //   navigate("users")
    //   ?.then(() => {
    //     setActiveUsersListPage(true);
    //     setActiveCreateUserForm(false);
    //   });
    // }
    // if (key === "3") {
    //   navigate("roles")
    //   ?.then(() => {
    //     setActiveRoleListPage(true);
    //     setActiveCreateRoleForm(false);
    //   });
    // }
    // if (key === "4") {
    //   navigate("limitSettings")
    //   ?.then(() => {});
    // }
    // if (key === "5") {
    //   navigate("import")
    //   ?.then(() => {});
    // }
    // if (key === "6") {
    //   navigate("logs")
    //   ?.then(() => {});
    // }
    // if (key === "7") {
    //   navigate("newsNotifications")
    //   ?.then(() => {});
    // }
    // if (key === "8") {
    //   navigate("generalSettings")
    //   ?.then(() => {});
    // }
    if (key === "1") {
      navigate("about")
        ?.then(() => {});
    }
    if (key === "2") {
      navigate("companyProfile")
        ?.then(() => {});
    }
  };

  // TODO: удалить или актуализировать согласно требованиям
  // const showUsersList = accountMe?.isSuperuser || accountMe?.permissions?.includes("list_users");
  // const showRolesList = accountMe?.isSuperuser || accountMe?.permissions?.includes("list_roles");

  const settingsItems: TabsProps["items"] = [
    // TODO: пока скрываем
    // showUsersList &&
    // {
    //   key: "1",
    //   label: "Пользователи",
    //   children: (
    //     <UsersList
    //       isActiveUsersListPage={isActiveUsersListPage}
    //       setActiveUsersListPage={setActiveUsersListPage}
    //       setActiveCreateUserForm={setActiveCreateUserForm}
    //     />
    //   ),
    // },
    // showRolesList &&
    // {
    //   key: "3",
    //   label: "Список ролей",
    //   children: (
    //     <RoleList
    //       isActiveRoleListPage={isActiveRoleListPage}
    //       setActiveRoleListPage={setActiveRoleListPage}
    //       isActiveCreateRoleForm={isActiveCreateRoleForm}
    //       setActiveCreateRoleForm={setActiveCreateRoleForm}
    //     />
    //   ),
    // },
    // {
    //   key: "4",
    //   label: "Страница настроек лимитов",
    //   children: (
    //     <LimitSettingsPage />
    //   ),
    // },
    // {
    //   key: "7",
    //   label: "Рассылки и уведомления",
    //   children: (
    //     <NewslettersNotifications />
    //   ),
    // },
    // // accountMe?.isSuperuser &&
    // {
    //   key: "8",
    //   label: "Общие настройки",
    //   children: (
    //     <GeneralSettings />
    //   ),
    // },
    {
      key: "1",
      label: "О компании",
      children: <AboutCompany />
    },
    {
      key: "2",
      label: "Профиль пользователя",
      children: <UserProfile />
    },
    isSupplier && {
      key: "3",
      label: "Распределение заказов",
      children: isSupplier && <DistributionOfOrders />
    }
  ];

  return (
    <>
      <h1>Настройки</h1>
      <br />
      <Tabs defaultActiveKey="1" items={settingsItems} onChange={callback} />
    </>
  );
};

export default Settings;
