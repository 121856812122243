import React, { FC, useState, useCallback, useRef } from "react";
import Sidebar from "@/components/pages/platform/sidebar";
import Header from "@/components/pages/platform/header";
import "@/assets/scss/global.scss";
import "@/assets/global.css";
import css from "./layout.modules.scss";
import UpIcon from "@/assets/icons/up.inline.svg";
import useMobile from "@/hooks/useMobile";
import usePageScroll from "@/hooks/usePageScroll";
import useScrollTo from "@/hooks/useScrollTo";
import { AnimatePresence, motion } from "framer-motion";
import { useWindowScroll, useLocation } from "react-use";

type LayoutPlatformPropsType = {
  children: React.ReactNode;
};

const LayoutPlatform: FC<LayoutPlatformPropsType> = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = useCallback(() => {
    if (isProfileOpen && !isNotification) {
      setIsMenuOpen((prev) => prev);
      setIsProfileOpen(false);
    } else {
      setIsMenuOpen((prev) => !prev);
    }

    if (isNotification) {
      setIsNotification(false);
      setIsMenuOpen(false);
    }
  }, [isMenuOpen, isProfileOpen, isNotification]);

  const closeMenu = () => {
    setIsMenuOpen(false);
    setIsProfileOpen(false);
  };

  const handleProfile = (isOpen: boolean) => {
    setIsProfileOpen(isOpen);
  };

  const { isMobile } = useMobile();
  const { isScrolled } = usePageScroll();

  const portal = useRef(null);

  const { scrollTo } = useScrollTo();
  const { y: winY } = useWindowScroll();
  const isHeader = location.pathname !== "/platform/search";
  return (
    <>
      <div></div>
      <div className={`bg-pale-grey-global-bg`}>
        {(isHeader || isMobile) && (
          <Header
            toggleMenu={toggleMenu}
            isMenuOpen={isMenuOpen}
            isNotification={isNotification}
            setIsNotification={setIsNotification}
          />
        )}
        <Sidebar
          closeMenu={closeMenu}
          isMenuOpen={isMenuOpen}
          isProfileOpen={isProfileOpen}
          handleProfile={handleProfile}
          isNotification={isNotification}
          setIsNotification={setIsNotification}
        />
        <main className={`px-6 tablet:px-8 ${css.main}`}>{children}</main>
        <div>
          <AnimatePresence>
            {isMobile && winY > 200 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.3 },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.3 },
                }}
                className={css.upButtonWrapper}
                style={{
                  marginBottom: portal ? portal.current.offsetHeight : "",
                }}
              >
                <button onClick={scrollTo} className={css.upButton}>
                  <UpIcon />
                </button>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div ref={portal} id="bottom-portal-root"></div>
    </>
  );
};

export default LayoutPlatform;
