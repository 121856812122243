import React, {
  FC,
  useState,
  useEffect,
} from "react";
import { AppStateType } from "@/reducers";
import { useDispatch, useSelector } from "react-redux";
import { navigate, RouteComponentProps } from "@reach/router";
import {
  getProductsCatalogList,
  getProductsList,
} from "@/actions/products.actions";
import CatalogPage from "./CatalogPage/CatalogPage";
import CatalogVerificationPage from "./CatalogVerificationPage/CatalogVerificationPage";
import { Tabs, TabsProps } from "antd";
import { roleResolver } from "@/utils/roleResolver";

interface ICatalogProps extends RouteComponentProps {}

const Catalog: FC<ICatalogProps> = () => {
  const dispatch = useDispatch();

  const [activeTabKey, setActiveTabKey] = useState<string>("1");
  const [currentActiveStatus, setCurrentActiveStatus] = useState<string>(null);
  const [supplierId, setSupplierId] = useState<number>(null);
  const [productName, setProductName] = useState<string>(null);
  const [productArticle, setProductArticle] = useState<string>(null);
  const [isProductsFiltersHovered, setProductsFiltersHovered] = useState<boolean>(false);
  const [isVerificationFiltersHovered, setVerificationFiltersHovered] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { userInfo, portalSettingsData } = useSelector((state: AppStateType) => state.account);
  const { updateProductStatus } = useSelector((state: AppStateType) => state.products);

  const { isAuditor } = roleResolver(userInfo?.roles);

  // В этом хуке обновляем заголовок сайта
  useEffect(() => {
    if (!!portalSettingsData) {
      document.title = portalSettingsData?.portalName;
    }
  }, [portalSettingsData]);

  useEffect(() => {
    isAuditor && dispatch(getProductsList({ page: 1, pageSize: 25 }));
    // TODO: выяснить, нужен ли этот запрос, если нет, то удалить
    // dispatch(getPortalSettingsData());
  }, []);

  useEffect(() => {
    dispatch(getProductsCatalogList({
      page: currentPage,
      pageSize: 25,
      supplierId: supplierId?.toString() || null,
      name: productName || null,
      article: productArticle || null,
    })); 
  }, [
    supplierId,
    productName,
    currentPage,
    productArticle,
    updateProductStatus
  ]);

  const callback = (key: string) => {
    setProductName(null);
    setProductArticle(null);
    setSupplierId(null);
    setCurrentPage(1);

    if (key === "1") {
      setVerificationFiltersHovered(false);
    }
    if (key === "2") {
      setProductsFiltersHovered(false);
    }
    
    setActiveTabKey(key);
  };

  const settingsItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Каталог",
      children: (
        <CatalogPage
          supplierId={supplierId}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setSupplierId={setSupplierId}
          setProductName={setProductName}
          productArticle={productArticle}
          setProductArticle={setProductArticle}
          currentActiveStatus={currentActiveStatus}
          isProductsFiltersHovered={isProductsFiltersHovered}
          setProductsFiltersHovered={setProductsFiltersHovered}
        />
      )
    },
    isAuditor && {
      key: "2",
      label: "Верификация",
      children: (
        <CatalogVerificationPage
          supplierId={supplierId}
          currentPage={currentPage}
          productName={productName}
          setCurrentPage={setCurrentPage}
          setSupplierId={setSupplierId}
          setProductName={setProductName}
          productArticle={productArticle}
          setProductArticle={setProductArticle}
          currentActiveStatus={currentActiveStatus}
          setCurrentActiveStatus={setCurrentActiveStatus}
          isVerificationFiltersHovered={isVerificationFiltersHovered}
          setVerificationFiltersHovered={setVerificationFiltersHovered}
        />
      )
    }
  ];

  return (
    <div>
      <h1
        data-aos="fade-zoom-in"
        data-aos-once="true"
      >
        Каталог
      </h1>
      <br />
      <Tabs defaultActiveKey="1" activeKey={activeTabKey} items={settingsItems} onChange={callback} />
    </div>
  );
};

export default Catalog;
