import React, { useState, useEffect, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "@reach/router";
import { useMedia } from "react-use";

import { useLocation } from "@reach/router";
import cn from "classnames";

import useBodyScrollLock from "@/hooks/useBodyScrollLock";
import { theme } from "@/../tailwind.config";
import { AppStateType } from "@/reducers";

import ListNotifications from "@/components/listNotifications";
import SidebarProfile from "./sidebarProfile";

import TickRight from "@/assets/icons/tick-right.inline.svg";
import Profile from "@/assets/icons/profile.inline.svg";

import css from "./sidebar.modules.scss";
import GatsbyLink from "gatsby-link";
import { getPortalSettingsData } from "@/actions/account.actions";

type LinkNavType = {
  title: string;
  url: string;
  isOutside: boolean;
  perms: string[];
};

const allRoles: string[] = ["Заказчик", "Аудитор", "Поставщик", "Согласовант", "Контролёр", "Демо"];

const LINKS_NAV: LinkNavType[] = [
  {
    title: "Главная",
    url: "/platform/home/",
    isOutside: false,
    perms: allRoles
  },
  {
    title: "Заказы",
    url: "/platform/orders/",
    isOutside: false,
    perms: allRoles
  },
  {
    title: "Каталог",
    url: "/platform/catalog/",
    isOutside: false,
    perms: allRoles
  },
  {
    title: "Поставщики",
    url: "/platform/suppliers/",
    isOutside: false,
    perms: ["Заказчик", "Аудитор"],
  },
  {
    title: "Отчеты",
    url: "/platform/reports/",
    isOutside: false,
    perms: ["Аудитор", "Контролёр"],
  }
];

type SidebarPropsType = {
  isMenuOpen: boolean;
  isProfileOpen: boolean;
  closeMenu: () => void;
  handleProfile: (isOpen: boolean) => void;
  isNotification: boolean;
  setIsNotification: (boolean) => void;
};

const Sidebar: FC<SidebarPropsType> = ({
  closeMenu,
  handleProfile,
  isMenuOpen,
  isProfileOpen,
  isNotification,
  setIsNotification,
}) => {
  const {
    screens: { tablet },
  } = theme;
  const isMobile = !useMedia(`(min-width: ${tablet})`);
  const dispatch = useDispatch();

  const scrollLockRef = useBodyScrollLock(isMenuOpen && isMobile);

  const {
    userInfo,
    portalSettingsData,
    savePortalSettingsStatus,
  } = useSelector((state: AppStateType) => state.account);

  const location = useLocation();

  const [activeLinkIndex, setActiveLinkIndex] = useState<number | null>(null);
  const [hoveredLinkIndex, setHoveredLinkIndex] = useState<number | null>(null);
  const [logoUrl, setLogoUrl] = useState<string>("");

  // В этом хуке обновляем заголовок сайта
  useEffect(() => {
    if (!!portalSettingsData) {
      document.title = portalSettingsData?.portalName;
    }
  }, [portalSettingsData]);

  useEffect(() => {
    setLogoUrl(portalSettingsData?.logoPath);
  }, [portalSettingsData, savePortalSettingsStatus]);

  // TODO: выяснить, нужен ли этот запрос, если нет, то удалить
  // useEffect(() => {
    // dispatch(getPortalSettingsData());
  // }, [savePortalSettingsStatus]);

  useEffect(() => {
    const index = LINKS_NAV.findIndex(
      (item) => {
        console.log('itemUrl, location.pathname', item.url, location.pathname);
        return item.url === location.pathname ||
        item.url === location.pathname + "/" //||
      }
    );
    setHoveredLinkIndex(index);
    setActiveLinkIndex(index);
  }, [location]);

  const hideSidebar = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === e.currentTarget) {
      closeMenu();
      handleProfile(false);
    }
  };

  const onMouseOver = (index: number) => {
    setHoveredLinkIndex(index);
  };
  const onMouseOut = () => {
    setHoveredLinkIndex(activeLinkIndex);
  };

  const openMobileProfile = () => {
    handleProfile(true);
  };

  const isMenu = isMenuOpen || !isMobile;
  const isMenuNotification = isMobile && isNotification;

  const navLinks: LinkNavType[] = LINKS_NAV?.filter((link: LinkNavType) =>
    userInfo?.roles?.some((role: string) => link.perms.includes(role)));

  return (
    <aside
      data-aos={isMobile ? "" : "fade-right"}
      data-aos-once="true"
      className={cn(`flex flex-col justify-between ${css.container}`, {
        [css.menuWrapperActive]: isMenu ? isMenu : isMenuNotification,
      })}
      onClick={hideSidebar}
    >
      <div
        className={cn(
          `flex flex-col justify-between tablet:border-r border-blue-grey ${css.wrapper}`,
          {
            [css.menuActive]: isMenu ? isMenu : isMenuNotification,
          }
        )}
      >
        <div
          className={`flex flex-col overflow-auto h-full`}
          ref={scrollLockRef}
        >
          {isMenu && !isProfileOpen && (
            <>
              <div className={`mx-8`}>
                {!logoUrl && (
                  <GatsbyLink to="/platform/home/" className="p-0 border-none">
                    <img
                      style={{ marginLeft: -15 }}
                      src={require("../../../../assets/images/logo.svg")}
                      alt={portalSettingsData?.portalName}
                    />
                  </GatsbyLink>
                )}
                {logoUrl && (
                  <GatsbyLink to="/platform/home/" className="p-0 border-none">
                    <img
                      style={{ marginLeft: -15 }}
                      src={logoUrl}
                      alt={portalSettingsData?.portalName}
                    />
                  </GatsbyLink>
                )}
              </div>
              <nav
                className={`pb-5 tablet:py-5 tablet:mt-30`}
                data-aos="fade-in"
                data-aos-once="true"
              >
                <ul className={`${css.list}`}>
                  <span
                    className={css.leftBorder}
                    style={{
                      height: `${100 / navLinks.length}%`,
                      transform: `translateY(${100 * +hoveredLinkIndex}%)`,
                    }}
                  >
                  </span>
                  {navLinks?.map(({ title, url, isOutside }, i) => (
                    <li key={i}>
                      {isOutside ? (
                        <a
                          href={url}
                          className={cn(
                            `border-b-0 pl-6 tablet:pl-8 block ${css.link}`,
                            {
                              [css.activeLink]: i === activeLinkIndex,
                            }
                          )}
                          onMouseOver={() => onMouseOver(i)}
                          onMouseOut={() => onMouseOut()}
                          onClick={closeMenu}
                        >
                          {title}
                        </a>
                      ) : (
                        <Link
                          to={url}
                          className={cn(
                            `border-b-0 pl-6 tablet:pl-8 block ${css.link}`,
                            {
                              [css.activeLink]: i === activeLinkIndex,
                            }
                          )}
                          onMouseOver={() => onMouseOver(i)}
                          onMouseOut={() => onMouseOut()}
                          onClick={closeMenu}
                        >
                          {title}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
                <div
                  onClick={openMobileProfile}
                  className={`flex items-center justify-between px-6 py-3
            border-t border-b border-dark-grey-txt cursor-pointer tablet:hidden mt-10`}
                >
                  <div className={`flex items-center`}>
                    <Profile className={`${css.accountIcon}`} />
                    <div>
                      <p
                        className={`${css.name}`}
                      >{`${userInfo?.name} ${userInfo?.family_name}`}</p>
                      <p className={`${css.email}`}>{userInfo?.preferred_username}</p>
                    </div>
                  </div>
                  <TickRight className={"ml-2 stroke-current"} />
                </div>
              </nav>
            </>
          )}
          {isMenuNotification && (
            <nav
              className={`pb-5 tablet:py-5 tablet:mt-30`}
              data-aos="fade-in"
              data-aos-once="true"
            >
              <ListNotifications
                isNotification={isNotification}
                setIsNotification={setIsNotification}
              />
            </nav>
          )}
          {isMobile && isMenuOpen && isProfileOpen && (
            <SidebarProfile
              handleProfile={handleProfile}
              closeMenu={closeMenu}
            />
          )}
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
